import React from 'react'
import ClientSuspense from '@components/ClientSuspense'
import Loading from '@components/SuspenseLoading/Loading'
import nl2br from '@helpers/misc/nl2br'
import useWidth from '@hooks/useWidth'
import Container from '@packages/justo-parts/lib/components/Container'

import Image from '../shared/Image'

import styles from './styles.module.css'

export default function ImageWithMessage({
  image,
  title,
  text,
}: {
  image: any
  title: string
  text: string
}) {
  const viewportWidth = useWidth()
  const isSmall = viewportWidth <= 768
  const imagePadding = 20

  return (
    <Container size="medium">
      <div className={styles.container}>
        <div className="row">
          <div className="col-xs-12 col-sm-6">
            <ClientSuspense fallback={<Loading />}>
              {image && (
                <Image
                  fileId={image._id}
                  alt=""
                  className={styles.image}
                  width={isSmall ? 360 : 1600}
                  style={{maxWidth: `calc(100% - ${imagePadding}px)`}}
                />
              )}
            </ClientSuspense>
          </div>
          <div className="col-xs-12 col-sm-6">
            <div className={styles.title}>{title}</div>
            <div className={styles.text}>{nl2br(text)}</div>
          </div>
        </div>
      </div>
    </Container>
  )
}
